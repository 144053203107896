import React, {PropsWithChildren, ReactNode} from "react";
import {Link} from "gatsby";

export interface ActionButtonProps {
    mode?: "light" | "dark",
    to: string
}

export default function ActionButton({children, to, mode = "dark"}: PropsWithChildren<ActionButtonProps>) {

    return (
        <Link to={to}>
            <span
                className={"cursor-pointer transition-colors px-6 py-4 font-poppins font-bold border-2 rounded-full " + (mode == "light" ? "border-white text-white hover:bg-white hover:text-black" : "border-black text-black hover:bg-black hover:text-white")}>
                {children}
            </span>
        </Link>
    )
}