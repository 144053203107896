import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";
import {Link} from "gatsby";

export default function Footer() {
    return (
      <section className="max-w-7xl mx-auto h-96 grid grid-cols-1 lg:grid-cols-2">
          <div className="mx-8 lg:mx-10 mt-10 lg:my-20">
              <div className="font-poppins font-bold text-2xl lg:text-4xl">Find IT People</div>
              <a className="block mt-5" href="https://www.linkedin.com/company/16240566">
                  <FontAwesomeIcon icon={faLinkedin} size="2x" />
              </a>
              
          </div>
          <div className="grid grid-cols-1 lg:my-20 mx-8 lg:mx-10 font-poppins underline">
              <div><Link to="/about">¿Por qué Find IT?</Link></div>
              <div><Link to="/services">¡Recluta!</Link></div>
              <div><Link to="/contact">Contáctanos</Link></div>
              <div><Link to="/privacy-policy">Políticas de Privacidad</Link></div>
              <div><a href="https://meetings.hubspot.com/find-it-people">Reserva una llamada con el equipo</a></div>
          </div>
      </section>  
    );
}