import React, {useCallback, useState} from "react";
import {StaticImage} from "gatsby-plugin-image";
import ActionButton from "./action-button";
import {Link} from "gatsby";
import { MenuIcon } from '@heroicons/react/outline'

export interface HeaderProps {
    mode?: "light" | "dark"
}

export default function Header({ mode = "light" }: HeaderProps) {
    const [menuVisible, setMenuVisible] = useState(false);
    const handleMenuClick = useCallback(() => setMenuVisible(prev => !prev), [setMenuVisible]);
    
    return (
        <div className={"flex w-full font-poppins font-thin " + (mode === "light" ? "text-white" : "text-black")}>
            <div className="z-20 xl:p-14 lg:p-8 p-5">
                <Link to="/">
                    {mode === "light" && !menuVisible ?
                        <StaticImage placeholder="none" src="../images/logo.png" alt="Logo" height={50} className="lg:w-72 w-44" />
                    :
                        <StaticImage placeholder="none" src="../images/logo-dark.png" alt="Logo" height={50} className="lg:w-72 w-44" />
                    }
                </Link>
            </div>
            <div className="flex-grow"/>
            <div className="hidden lg:block xl:pt-16 pt-10 px-4"><Link to="/about">¿Por qué Find IT?</Link></div>
            <div className="hidden lg:block xl:pt-16 pt-10 px-4"><Link to="/services">¡Recluta!</Link></div>
            <div className="hidden lg:block xl:pt-16 pt-10 px-4"><Link to="/contact">Contáctanos</Link></div>
            <div className="hidden lg:block xl:mt-12 m-7 py-3"><ActionButton mode={mode} to="/lets-start">¡Comencemos!</ActionButton></div>
            <div className="lg:hidden mt-3 mr-4 cursor-pointer z-20">
                <MenuIcon className={"h-10 w-10 transition-colors " + (menuVisible || mode === "dark" ? "text-black" : "text-white")} onClick={handleMenuClick} />
            </div>
            <div className={"lg:hidden text-black text-xl flex flex-col justify-center items-center absolute z-10 bg-white w-full transition-all " + (menuVisible ? "opacity-100 h-screen" : "h-0 opacity-0")} >
                <div className="my-5"><Link to="/about">¿Por qué Find IT?</Link></div>
                <div className="my-5"><Link to="/services">¡Recluta!</Link></div>
                <div className="my-5"><Link to="/contact">Contáctanos</Link></div>
                <div className="my-10"><ActionButton mode="dark" to="/lets-start">¡Comencemos!</ActionButton></div>
            </div>
        </div>
    );
}